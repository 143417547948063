import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  TwinWebsocket,
  TwinPlatform,
  TwinAppEvent,
  AppMode,
  Locale,
  TwinApiEvent,
  ComponentTypes,
} from "@twin-shared";
import { AppDispatch, RootState, useAppDispatch } from "../app/store";
import {
  addMessage,
  setMimiIsTyping,
  updateMessage,
} from "../features/message/messageSlice";
import { clearTextChat } from "../features/chat/chatSlice";
import { useSelector } from "react-redux";
import {
  setAppSetting,
  setAuthToken,
  setSessionStartTime,
  updateSessionTime,
} from "../features/app/appSlice";
import { updateCartList } from "../features/cartlist/cartlistSlice";
import env from "react-dotenv";
import { store } from "../app/store";
import { PUSH_NOTIFICATION_TOKEN_ID } from "@twin-shared/lib/constants";

interface ContextProps {
  isOnline: boolean;
  changeMode: (mode: AppMode) => void;
  sendMessage: (message: any) => void;
  sendVoice: (chunk: any, seqNo: number) => void;
  sendVoiceEnd: (chunk: any, seqNo?: number) => void;
  setNotification: (message: any) => void;
  changeLocale: (locale: Locale) => void;
  overrideAuthToken: (token: string) => void;
  sendUiResponse: (messageId: string, data: any) => void;
  sendNotificationCallback: (data: any) => void;
  sendUiComponentChange: (
    messageId: string,
    componentId: string,
    value: any
  ) => void;
  sendUiComponentQuery: (
    messageId: string,
    componentId: string,
    action: any,
    query: any,
    category: string
  ) => Promise<any>;
  sendUiComponentAction: (
    messageId: string,
    componentId: string,
    action: any,
    data: any
  ) => void;
  reconnect: () => void;
}

export const SocketContext = createContext<ContextProps>({
  isOnline: false,
  changeMode: () => null,
  sendMessage: () => null,
  sendVoice: () => null,
  sendVoiceEnd: () => null,
  setNotification: () => null,
  changeLocale: () => null,
  overrideAuthToken: () => null,
  sendUiResponse: () => null,
  sendUiComponentChange: () => null,
  sendNotificationCallback: () => null,
  sendUiComponentQuery: async () => null,
  sendUiComponentAction: () => null,
  reconnect: () => null,
});

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { WEB_API_URL } = env;
  const textChat = useSelector((state: RootState) => state.chat.textChat);
  const authToken =
    useSelector((state: RootState) => state.app.authToken) || null;
  const { isRecordingActive } = useSelector((state: RootState) => state.message);
  const dispatch: AppDispatch = useAppDispatch();
  const [isOnline, setIsOnline] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const currentAudioRef = useRef(null);

  const socketRef = useRef<TwinWebsocket>(
    new TwinWebsocket(
      WEB_API_URL,
      TwinPlatform.Web,
      { AUTH_TOKEN: authToken }
    )
  );

  //#region Socket event bind
  /**
   * changeMode
   * @param mode
   * @returns
   */
  const changeMode = (mode: AppMode) => socketRef.current?.setMode(mode);

  /**
   *sendMessage
   * @param message
   */
  const sendMessage = (message: any) => {
    if (!socketRef.current.connected) {
      window.dispatchEvent(new CustomEvent(TwinAppEvent.onReconnecting));
    }
    socketRef.current?.message(message);
    if (message.payload === "\n") {
      dispatch(
        addMessage({
          payload: textChat,
          type: "text",
          sender: "user",
        })
      );
      dispatch(clearTextChat());
      socketRef.current.connected && dispatch(setMimiIsTyping(true));
    }
    setTimeout(
      () => window.dispatchEvent(new CustomEvent(TwinAppEvent.onScrollToEnd)),
      500
    );
  };

  /**
   * sendVoice
   * @param chunk
   * @param seqNo
   * @returns
   */
  const sendVoice = (chunk: any, seqNo: number) => {
    if (!socketRef.current.connected) {
      window.dispatchEvent(new CustomEvent(TwinAppEvent.onReconnecting));
    }
    socketRef.current?.voiceChunk(chunk, seqNo);
  };

  /**
   * sendVoiceEnd
   * @param label
   * @returns
   */
  const sendVoiceEnd = (label: string) => socketRef.current?.voiceEnd(label);

  /**
   * setNotification
   * @param message
   * @returns
   */
  const setNotification = (message: any) =>
    socketRef.current?.setNotification(message);

  /**
   * reconnect
   * @returns
   */
  const reconnect = () => {
    window.dispatchEvent(new CustomEvent(TwinAppEvent.onReconnecting));
    socketRef.current?.reconnect();
  };

  /**
   * changeLocale
   * @param locale
   * @returns
   */
  const changeLocale = (locale: Locale) => socketRef.current?.setLocale(locale);

  /**
   * overrideAuthToken
   * @param token
   * @returns
   */
  const overrideAuthToken = (token: string) =>
    socketRef.current?.overrideAuthToken(token);

  /**
   * sendUiResponse
   * @param messageId
   * @param data
   * @returns
   */
  const sendUiResponse = (messageId: string, data: any) =>
    socketRef.current?.uiResponse(messageId, data);

  /**
   * sendUiComponentChange
   * @param messageId
   * @param componentId
   * @param value
   * @returns
   */
  const sendUiComponentChange = (
    messageId: string,
    componentId: string,
    value: any
  ) => socketRef.current?.uiComponentChange(messageId, componentId, value);

  /**
   * sendUiComponentQuery
   * @param messageId
   * @param componentId
   * @param action
   * @param query
   * @param category
   * @returns
   */
  const sendUiComponentQuery = (
    messageId: string,
    componentId: string,
    action: any,
    query: any,
    category: string
  ) =>
    socketRef.current?.uiComponentQuery(
      messageId,
      componentId,
      action,
      query,
      category
    );

  /**
   *
   * @param data
   * @returns
   */
  const sendNotificationCallback = (data: any) =>
    socketRef.current.sendNotificationCallback(data);

  /**
   * sendUiComponentAction
   * @param messageId
   * @param componentId
   * @param action
   * @param data
   * @returns
   */
  const sendUiComponentAction = (
    messageId: string,
    componentId: string,
    action: any,
    data: any
  ) =>{
    socketRef.current?.uiComponentAction(messageId, componentId, action, data);
    dispatch(setMimiIsTyping(true));
  }

  //#endregion

  useEffect(() => {
    socketRef.current?.connect();
    window.dispatchEvent(new CustomEvent(TwinAppEvent.onConnecting));

    return () => {
      socketRef.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (authToken !== null && authToken !== "") {
      socketRef.current?.overrideAuthToken(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      const { lastActivityTime, appSetting } = store.getState().app;
      if (
        (Date.now() - lastActivityTime) / 1000 > appSetting?.timeoutInSeconds &&
        socketRef.current?.connected
      ) {
        console.log("disconnecting");
        socketRef.current?.disconnect();
        setIsOnline(false);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentAudioRef.current && (isRecordingActive ||  textChat && textChat.length>0 )) {
        currentAudioRef.current.pause();
      } 
  },[isRecordingActive,currentAudioRef.current,textChat]);

  // Register all event listener to websocket
  useEffect(() => {
      socketRef.current?.addEventListener(TwinApiEvent.Connect, (message) => {
      setIsOnline(true);
      setIsSocketConnected(true);
      dispatch(setSessionStartTime(Date.now()));
    });
    socketRef.current?.addEventListener(TwinApiEvent.Disconnect, () => {
      setIsSocketConnected(false);
    });
    socketRef.current?.addEventListener(TwinApiEvent.MessageAck, (message) => {
      if(message.label === "voice_transcript" ){
        dispatch(setMimiIsTyping(true));
      }else{
        dispatch(setMimiIsTyping(!!message?.keep_spinner));  
      }
      messageAckHandler(message)
    }
    );
    socketRef.current?.addEventListener(
      TwinApiEvent.Notification,
      ({ fcmTokenId }: { fcmTokenId: string }) =>
        localStorage.setItem(PUSH_NOTIFICATION_TOKEN_ID, fcmTokenId)
    );
    socketRef.current?.addEventListener(TwinApiEvent.VoiceAck, (message) => {
      dispatch(setMimiIsTyping(!!message?.keep_spinner)); 
      // Exit early if recording is active or there is text in the chat
      if (isRecordingActive || (textChat?.length > 0)) return; 
      // Stop any currently playing audio
      currentAudioRef.current?.pause();
      // Create and play new audio
      const audio_ack = new Audio(`data:audio/wav;base64,${message.msg}`);
      currentAudioRef.current = audio_ack;  
      audio_ack.play().catch((err) => console.log('Audio playback error:', err));
    });
    socketRef.current?.addEventListener(
      TwinApiEvent.VoiceDuration,
      (message) => {
        console.log(message);
      }
    );
    socketRef.current?.addEventListener(TwinApiEvent.UiRequest, (message) => {
      dispatch(setMimiIsTyping(!!message?.keep_spinner));  
      uiRequestHandler(message);
    });
    socketRef.current?.addEventListener(TwinApiEvent.UiResponseAck, (message) =>
      dispatch(updateMessage(message))
    );
    socketRef.current?.addEventListener(TwinApiEvent.AnyOutgoingMessage, () => {
      dispatch(updateSessionTime(Date.now()));
    });
    socketRef.current?.addEventListener(
      TwinApiEvent.AppSetting,
      (message: any) => dispatch(setAppSetting(message))
    );
    socketRef.current?.addEventListener(TwinApiEvent.Error, (message: any) => {
      window.dispatchEvent(
        new CustomEvent(TwinAppEvent.onConnectionError, message)
      ),
        setIsOnline(false);
      setIsSocketConnected(false);
    });

    return () => {
      socketRef.current?.removeEventListenerAll();
    };
  }, []);

  const messageAckHandler = (message: { msg: string; label: string }) => {
    if (message.label === "auth_token") {
      dispatch(setAuthToken(message.msg));
      dispatch(setMimiIsTyping(false));
    } else {
      dispatch(
        addMessage({
          payload: message.msg.replace(/\.$/, ""),
          type: "text",
          sender: message.label === "voice_transcript" ? "user" : "mimi",
        })
      );
    }
    window.dispatchEvent(new CustomEvent(TwinAppEvent.onScrollToEnd));
  };

  const uiRequestHandler = (message: {
    payload: any;
    message_id: any;
    msg: string;
    label: string;
  }) => {
    message.payload["message_id"] = message?.message_id;

    // Check for Cart List
    const firstChild = message.payload?.children?.[0];
    const hasNoView =
      message.payload?.children?.length === 1 &&
      (firstChild?.type === ComponentTypes.YourCart ||
        firstChild?.type === ComponentTypes.YourList);

    if (hasNoView) {
      dispatch(
        updateCartList({
          components: message.payload?.children,
          messageId: message?.message_id,
        })
      );
    } else {
      dispatch(
        addMessage({
          payload: message.payload,
          type: "ui_component",
          sender: "mimi",
          messageId: message.message_id,
        })
      );

      setTimeout(() => {
        const allDivs = document.querySelectorAll("#chat > div") ?? [];
        const lastDiv = allDivs[0];
        if (lastDiv) {
          const latestForm = lastDiv.querySelectorAll("form");
          if (latestForm && latestForm.length > 0) {
            const elements = lastDiv.querySelectorAll("input");
            if (elements && elements.length > 0) {
              elements[0]?.focus();
            }
          }
        }
        window.dispatchEvent(new CustomEvent(TwinAppEvent.onScrollToElement));
      }, 100);
    }
  };

  return (
    <SocketContext.Provider
      value={{
        isOnline,
        changeMode,
        sendMessage,
        sendVoice,
        sendVoiceEnd,
        setNotification,
        changeLocale,
        overrideAuthToken,
        sendUiResponse,
        sendUiComponentChange,
        sendUiComponentQuery,
        sendUiComponentAction,
        sendNotificationCallback,
        reconnect,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error("SocketContext must be used inside the SocketProvider");
  }

  return context;
};
